import React from 'react'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
import { IRS_CORNER } from '../../components/constant'
import IMGIRScorner from '../../images/new/DSC_8745.jpeg'
const IRSCorner = () => {
  return (
    <div>
      <Layout>
        <SEO title="IRS Corner" />
        <PageStructure
          title="IRS Corner"
          desc="TESTIG"
          mode={IRS_CORNER}
          image={IMGIRScorner}
        />
      </Layout>
    </div>
  )
}

export default IRSCorner
